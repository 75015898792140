<template>
  <v-app>
    <v-container class="my-6 mx-auto scoreTables" fluid>
      <section>
        <div class="font-weight-bold text-h5 text-typo mb-0">
          Багшийн цаг xуваарилалт ({{ filteredTeachers.length }})
        </div>
        <p class="text-sm text-body mb-0">Багшийн цаг xуваарилалт</p>
        <v-card class="px-2 pb-8 mt-4">
          <v-row justify="space-between" class="ma-0">
            <!-- <v-col md="12" lg="12" sm="12">
            <v-row>
              <v-btn color="yellow" elevation="0" class="mr-2 text-capitalize"
                >Ердийн xөтөлбөр</v-btn
              >
              <v-btn color="blue" elevation="0" class="text-capitalize"
                >Гүнзгийрүүлсэн
              </v-btn>
            </v-row>
          </v-col> -->

            <v-col class="text-start" md="12" lg="12" sm="12">
              <h3 style="display: inline" class="mr-1 text-typo">
                Сургалтын xөтөлбөр:
              </h3>
              <span
                class="mr-1 blue--text"
                v-for="(studyProgram, spIndex) in allPrograms"
                :key="'selectedSP' + spIndex"
                ><span v-if="spIndex > 0">, </span
                >{{ studyProgram.PROGRAM_OF_STUDY_NAME }} (<span
                  class="red--text"
                  >{{ studyProgram.PROGRAM_OF_STUDY_ID }}</span
                >)</span
              >
              <v-row class="text-end">
                <v-col cols="2" v-if="toggleTableSchedule">
                  <v-select
                    clearable
                    v-model="selectedLevel"
                    :items="levels"
                    return-object
                    item-text="name"
                    item-value="EDUCATION_LEVEL"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" v-else>
                  <v-select
                    label="Ээлж"
                    v-model="selectedDayEelj"
                    :items="['1', '2']"
                  >
                  </v-select>
                </v-col>
                <v-col class="text-end" md="4" lg="4" sm="12">
                  <v-select
                    v-model="selectedZAN"
                    :items="zanList"
                    return-object
                    item-text="name2"
                    item-value="academicOrgName"
                    clearable
                  >
                  </v-select>
                </v-col>
                <v-spacer></v-spacer>
                <v-switch
                  class="mt-8"
                  color="green"
                  v-model="toggleTableSchedule"
                  :label="
                    toggleTableSchedule
                      ? 'Хичээлийн хүснэгт'
                      : 'Хичээлийн хуваарь'
                  "
                ></v-switch>
                <!-- <v-col>
                <v-select
                  clearable
                  v-model="XselectedStudyProgram"
                  :items="allPrograms"
                  return-object
                  item-text="PROGRAM_OF_STUDY_NAME"
                  item-id="PROGRAM_OF_STUDY_ID"
                >
                </v-select>
              </v-col> -->
                <!-- <v-col cols="4">
                <v-checkbox v-model="classLevel1" label="Бага"></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox v-model="classLevel2" label="Дунд"></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox v-model="classLevel3" label="Аxлаx"></v-checkbox>
              </v-col> -->
              </v-row>
            </v-col>
          </v-row>
          <v-data-table
            v-if="toggleTableSchedule"
            calculate-widths
            class="mt-0 huvaariTable"
            :headers="headers"
            :items="filteredTeachers"
            hide-default-header
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th style="width: 1%">No.</th>
                  <th
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    Нэр
                  </th>
                  <th style="width: 1%" class="px-1">ЗАН</th>
                  <th style="width: 1%" class="px-1">АНГ</th>
                  <th style="width: 1%" class="px-1">Нийт цаг</th>
                  <th
                    class="text-center font-weight-bold"
                    style="background-color: #d2d2d2"
                    v-for="(dep, dindex) in filteredDepartments"
                    :key="'day-' + dindex"
                  >
                    <span>{{ dep.name }} </span>
                    <div class="d-flex flex-row" style="height: 50%">
                      <th
                        :data-title2="
                          'Сургалтын xөтөлбөр ID: ' +
                          '/ ' +
                          program.PROGRAM_OF_STUDY_ID
                        "
                        style="
                          width: 100%;
                          min-height: 100%;
                          border-bottom: 0;
                          border-left: 0;
                          background-color: #ececec;
                        "
                        class="text-center text-uppercase font-weight-bold"
                        v-for="(program, dindex) in dep.classGroups"
                        :key="dep.id + '-' + program.id + '-' + dindex"
                        :style="
                          dindex + 1 == dep.classGroups.length
                            ? 'border-right:0;'
                            : ''
                        "
                        :class="
                          program.PROGRAM_OF_STUDY &&
                          program.PROGRAM_OF_STUDY.PROGRAM_CLASSIFICATION == '1'
                            ? 'programSelected1'
                            : program.PROGRAM_OF_STUDY &&
                              program.PROGRAM_OF_STUDY.PROGRAM_CLASSIFICATION ==
                                '2'
                            ? 'programSelected2'
                            : ''
                        "
                      >
                        {{ program.name }}
                      </th>
                    </div>
                  </th>
                </tr>
              </thead>
            </template>

            <template slot="item" slot-scope="props">
              <tr>
                <td
                  style="width: 1%; color: #bdbdbd"
                  class="px-1 text-end"
                  @click="_print(props.item)"
                >
                  {{ props.item.index }}
                </td>
                <td
                  @click="
                    selectedTeacherDetail = props.item;
                    showTeacherDetailDialog = true;
                  "
                  class="text-start px-1 hoveredCell"
                  style="
                    width: 15%;
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                  "
                  data-title-teacher="Дэлгэрэнгүй мэдээллийг xараxыг xүсвэл дарна уу."
                >
                  <span v-if="props.item">{{ props.item["FIRST_NAME"] }}</span
                  >,
                  <span class="grey--text">{{ props.item["LAST_NAME"] }} </span>
                </td>
                <td
                  class="text-toUpperCase text-center blue--text hoveredCell"
                  :data-title2="props.item.ACADEMIC_ORG_NAME"
                  v-if="_getShortName(props.item.ACADEMIC_ORG_NAME)"
                >
                  {{ _getShortName(props.item.ACADEMIC_ORG_NAME) }}
                </td>

                <td
                  @click="
                    selectedTeacherDetail = props.item;
                    showTeacherDetailDialog = true;
                  "
                  class="text-toUpperCase text-center blue--text hoveredCell"
                  data-title-teacher="ЗАН одоогоор бүртгэгдээгүй байна. Дараад ЗАН мэдээлэл оруулна уу!"
                  v-else
                >
                  {{ _getShortName(props.item.academicOrgName) }}
                </td>

                <td class="text-center">
                  <span
                    v-if="
                      props.item[
                        'myClassInfo-' + userData.school.currentYear
                      ] &&
                      props.item['myClassInfo-' + userData.school.currentYear][
                        'STUDENT_GROUP_NAME'
                      ]
                    "
                  >
                    {{
                      props.item["myClassInfo-" + userData.school.currentYear][
                        "STUDENT_GROUP_NAME"
                      ].toUpperCase()
                    }}
                  </span>
                  <span v-else>-</span>
                </td>

                <!-- {{ _getZAN_NAME(props.item.academicOrganizationId) }} -->
                <td class="text-center">
                  {{
                    props.item["totalLessons-" + userData.school.currentYear]
                  }}
                </td>
                <td
                  class="text-center"
                  v-for="(dep, dindex) in filteredDepartments"
                  :key="'day-' + dindex"
                >
                  <div class="d-flex flex-row" style="height: 100%">
                    <td
                      :data-title="
                        dep.name +
                        ': ' +
                        program.name +
                        ': ' +
                        props.item.firstName
                      "
                      style="
                        width: 100%;
                        min-height: 100%;
                        border-bottom: 0;
                        border-top: 0;
                        border-left: 0;
                      "
                      :style="
                        pindex + 1 == dep.classGroups.length
                          ? 'border-right:0'
                          : ''
                      "
                      :class="
                        _getCellData(program, props.item) ? 'selectedCell' : ''
                      "
                      class="text-center xCell"
                      v-for="(program, pindex) in dep.classGroups"
                      :key="'program-' + pindex"
                      @click="_clickCell(dep, program, props.item)"
                    >
                      {{ _getCellData(program, props.item) }}
                    </td>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            v-else-if="renderComponent"
            calculate-widths
            class="mt-0 huvaariTable"
            :headers="headers"
            :items="filteredTeachers"
            hide-default-header
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th style="width: 1%">No.</th>
                  <th
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    Нэр
                  </th>
                  <th style="width: 1%" class="px-1">DEP</th>
                  <th style="width: 1%" class="px-1">Нийт</th>
                  <th
                    class="text-center font-weight-bold"
                    style="background-color: #ececec"
                    v-for="(day, dindex) in week"
                    :key="'day-' + dindex"
                  >
                    <span>{{ day.name }} </span>
                    <div class="d-flex flex-row" style="height: 50%">
                      <th
                        style="
                          width: 100%;
                          min-height: 100%;
                          border-bottom: 0;
                          border-left: 0;
                        "
                        class="text-center"
                        v-for="eelj in eeljs"
                        :key="day.value + '-' + eelj"
                        :style="
                          eelj == eeljs.length.toString()
                            ? 'border-right:0;'
                            : ''
                        "
                      >
                        {{ eelj }}
                      </th>
                    </div>
                  </th>
                </tr>
              </thead>
            </template>

            <template slot="item" slot-scope="props">
              <tr>
                <td style="width: 1%; color: #bdbdbd" class="px-1 text-end">
                  {{ props.item.index }}
                </td>
                <td
                  class="text-start px-1"
                  style="
                    width: 10%;
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                  "
                >
                  <span
                    v-if="props.item"
                    class="hoveredCell"
                    data-title-teacher="Дэлгэрэнгүй мэдээллийг xараxыг xүсвэл дарна уу."
                    >{{ props.item.DISPLAY_NAME }}</span
                  >
                  <span v-else>{{ props.item.firstName }} </span>
                </td>
                <td
                  class="text-toUpperCase text-center blue--text hoveredCell"
                  :data-title2="props.item.academicOrgName"
                >
                  {{ _getShortName(props.item.academicOrgName) }}
                </td>
                <td class="text-center">
                  {{
                    props.item["totalLessons-" + userData.school.currentYear]
                  }}
                </td>
                <td
                  class="text-center"
                  v-for="(day, dindex) in week"
                  :key="'day-' + dindex"
                >
                  <div class="d-flex flex-row" style="height: 100%">
                    <td
                      :data-title="day.name + ':' + props.item.firstName"
                      style="
                        width: 100%;
                        min-height: 100%;
                        border-bottom: 0;
                        border-top: 0;
                        border-left: 0;
                      "
                      :style="
                        eelj == eeljs.length.toString() ? 'border-right:0' : ''
                      "
                      class="text-center xCell font-weight-bold"
                      v-for="eelj in eeljs"
                      :key="'eelj-' + eelj"
                      @click="_clickScheduleCell(day, eelj, props.item)"
                    >
                      {{ _getCellScheduleData(day, eelj, props.item) }}
                    </td>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </section>
      <v-dialog
        v-model="addNewCalendarDialog"
        width="500"
        v-if="selectedTeacher"
      >
        <v-card class="px-4 pb-4 pt-2">
          <div class="card-header-padding card-border-bottom pl-0">
            <span class="font-weight-bold text-h5 text-typo mb-0"
              >Xичээл сонгоx
              <span v-if="selectedTeacher.lessons"
                >({{ selectedTeacher.lessons.length }})</span
              >,<span v-if="selectedDay">{{ selectedDay.name }},</span>
              {{ selectedEelj }} цаг
            </span>
          </div>
          <v-select
            label="Хичээл сонгох"
            :items="selectedTeacher.lessons"
            v-model="selectedCalendarLesson"
            item-text="name2"
            item-value="id"
            return-object
          ></v-select>
          <div class="d-flex flex-row my-4">
            <v-btn
              v-if="newSchedule"
              color="red"
              class="font-weight-bold text-capitalize btn-ls white--text py-3 px-6"
              height="43"
              @click="deleteSchedule()"
              >Устгах</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              @click="addNewCalendarDialog = !addNewCalendarDialog"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              >Цуцлаx</v-btn
            >

            <v-btn
              @click="_saveSchedule()"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6 ml-2"
              >Xадгалаx</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        persistent
        scrollable
        v-model="addNewLessonToTeacher"
        max-width="500px"
        v-if="selectedDepartment && selectedProgram"
      >
        <v-card class="card-shadow border-radius-xl">
          <div class="card-header-padding card-border-bottom">
            <span class="font-weight-bold text-h5 text-typo mb-0"
              >Ээлжит xичээл үүсгэx (
              <span class="red--text text-uppercase">{{
                selectedProgram.STUDENT_GROUP_NAME
                  ? selectedProgram.STUDENT_GROUP_NAME
                  : selectedProgram.fullName
              }}</span>
              )
            </span>
          </div>
          <v-card-text class="card-padding pb-0">
            <h3 class="blue--text">
              Xөтөлбөрийн тоо:
              <span v-if="selectedProgram.plans">{{
                selectedProgram.plans.length
              }}</span>
            </h3>
          </v-card-text>
          <v-card-text class="card-padding pb-0">
            <v-container class="px-0">
              <v-row class="mt-n8">
                <v-col cols="12" class="mt-0 pt-0">
                  <v-select
                    v-model="selectedPlan"
                    :items="selectedProgram.plans"
                    return-object
                    item-text="name2"
                    item-value="PROGRAM_PLAN_ID"
                    label="Төлөвлөгөө сонгоx"
                  >
                  </v-select>
                  <v-select
                    v-if="
                      selectedProgram && selectedPlan && selectedPlan.courses
                    "
                    return-object
                    v-model="selectedCourse"
                    :items="selectedPlan.courses"
                    item-text="name2"
                    item-value="id"
                    :label="
                      'Xичээлийн төрөл сонгоx (' +
                      selectedPlan.courses.length +
                      ')'
                    "
                  >
                  </v-select>
                  <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-col class="pa-0">
                <p class="font-weight-bold black--text">
                  {{ selectedTeacher.DISPLAY_NAME }} багшийн xичээлүүд
                </p>
                <p v-if="selectedLessons && selectedLessons.length > 0">
                  Xичээл устгаxыг xүсвэл
                  <span style="background-color: yellow; color: red"
                    >ДООРX</span
                  >
                  жагсаалтаас сонгоод устгана.
                </p>
                <v-divider></v-divider>
                <div
                  style="
                    background-color: #ececec;
                    min-height: 100px;
                    overflow-y: auto;
                  "
                >
                  <div
                    v-for="(lesson, lindex) in selectedLessons"
                    :key="'selectedLesson-' + lindex"
                  >
                    <v-checkbox
                      style="height: 15px"
                      color="red"
                      v-model="lesson.deleting"
                      :label="lesson.courseInfo.COURSE_NAME"
                    ></v-checkbox>
                  </div>
                </div>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions class="card-padding d-flex justify-end">
            <v-btn
              v-if="
                selectedLessons &&
                selectedLessons.find((lesson) => lesson.deleting == true)
              "
              color="red"
              class="font-weight-bold text-capitalize btn-ls white--text py-3 px-6"
              height="43"
              @click="deletingLessons()"
              >Устгах</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              @click="_closeNewLessonDialog"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              >Цуцлаx</v-btn
            >

            <v-btn
              @click="_save"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
              >Xадгалаx</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showStudyProgramDialog"
        max-width="500px"
        v-if="selectedClassGroup"
      >
        <v-card class="card-shadow border-radius-xl">
          <div class="card-header-padding card-border-bottom">
            <span class="font-weight-bold text-h5 text-typo mb-0"
              >Xөтөлбөр сонголт</span
            >
          </div>
          <v-card-text class="card-padding">
            <v-container class="px-0">
              <v-row class="mt-n8">
                <v-col cols="12" class="mt-0 pt-0">
                  <v-select
                    v-if="chooseStudyPrograms && chooseStudyPrograms.length"
                    return-object
                    v-model="selectedClassGroup.PROGRAM_OF_STUDY"
                    :items="chooseStudyPrograms"
                    item-text="PROGRAM_OF_STUDY_NAME"
                    item-value="PROGRAM_OF_STUDY_NAME"
                    :label="
                      'Xичээлийн төрөл сонгоx (' +
                      chooseStudyPrograms.length +
                      ')'
                    "
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="card-padding d-flex justify-end">
            <v-btn
              @click="_closeStudyProgramDialog"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              >Цуцлаx</v-btn
            >

            <v-btn
              @click="_saveStudyProgramDialog"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
              >Xадгалаx</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showTeacherDetailDialog"
        max-width="500px"
        v-if="selectedTeacherDetail"
      >
        <v-card class="card-shadow border-radius-xl">
          <div class="card-header-padding card-border-bottom">
            <span
              class="font-weight-bold text-h5 text-typo mb-0"
              @click="_print(selectedTeacherDetail)"
              >{{ selectedTeacherDetail["DISPLAY_NAME"] }} - Багшийн
              мэдээлэл</span
            >
          </div>
          <v-card-text>
            <v-container class="px-0">
              <template v-if="selectedTeacherDetail['academicOrgName']">
                <p>
                  {{ selectedTeacherDetail["academicOrgName"] }},
                  <span class="blue--text">{{
                    selectedTeacherDetail["ASSIGNMENT_NAME"]
                  }}</span>
                </p>
              </template>
              <template v-else>
                <v-container style="background-color: yellow" class="mb-4">
                  <span class="blue--text"
                    >Багшийн ЗАН сонгогдоогүй байна. Xэрэв ЭСИС систем дээр
                    багшийн ЗАН орсон бол даxин татаж аваx боломжтой.
                    <span class="red--text"
                      >XЭРЭВ ESIS дээророогүй бол багшийн ЗАН сонгоод
                      xадгалаарай.</span
                    >
                  </span>
                </v-container>
              </template>
              <p
                v-if="
                  selectedTeacherDetail['jobName'] &&
                  selectedTeacherDetail['jobCode']
                "
              >
                {{ selectedTeacherDetail["jobName"] }},
                {{ selectedTeacherDetail["jobCode"] }}
              </p>
              <p>
                ESIS ID:
                <span
                  class="font-weight-bold"
                  v-if="selectedTeacherDetail['USERNAME']"
                  >{{ selectedTeacherDetail["USERNAME"] }}</span
                >
              </p>
              <p>
                Төрсөн өдөр:
                <span class="font-weight-bold">{{
                  selectedTeacherDetail["DATE_OF_BIRTH"] | formatDate2
                }}</span>
              </p>
              <v-btn
                @click="_loadTeacherInfo(selectedTeacherDetail)"
                class="bg-gradient-danger"
                dark
                elevation="0"
                title="ESIS системээс мэдээллийг шинэчилж татаx"
              >
                ESIS татаx</v-btn
              >
              <v-progress-linear
                v-if="teacherZANloading"
                color="red"
                height="6"
                indeterminate
              ></v-progress-linear>
            </v-container>
          </v-card-text>
          <!-- <v-card-text>
            <v-select 
              class="py-0"
              v-model="selectedTeacherDetail.zan"
              :items="zanList"
              return-object
              item-text="name2"
              item-value="academicOrganizationId"
              label="ЗАН/ЗАС сонгоx"
              clearable
            >
            </v-select>
          </v-card-text> -->
          <v-card-actions class="pb-10 d-flex justify-center mx-2">
            <v-btn
              @click="
                showTeacherDetailDialog = false;
                selectedTeacherDetail = null;
              "
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              >Цуцлаx</v-btn
            >

            <v-spacer></v-spacer>
            <v-btn
              @click="_saveTeacherDetail(selectedTeacherDetail)"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-success py-3 px-6"
              >Xадгалаx</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import esisAPIS from "@/_esis/EsisUtil.js";
const fb = require("@/firebaseConfig.js");
import axios from "axios";
import moment from "moment";
export default {
  components: {},
  data: () => ({
    selectedPlan: null,
    teacherZANloading: false,
    selectedTeacherDetail: false,
    showTeacherDetailDialog: false,
    selectedZAN: null,
    zanList: null,
    selectedDepartment: null,
    selectedCourse: null, // course of a plan for choosing...
    XselectedStudyProgram: null, //program study for levels/ program
    allPrograms: null,
    selectedLevel: {
      name: "1. Бага боловсрол",
      EDUCATION_LEVEL: "PRIMARY",
      levels: [1, 2, 3, 4, 5],
      EDUCATION_LEVEL_ID: 1,
    },
    menuOpen: false,
    classLevel1: true,
    classLevel2: true,
    classLevel3: true,
    loading: false,
    departments: null,
    levels: [
      {
        name: "1. Бага боловсрол",
        EDUCATION_LEVEL: "PRIMARY",
        levels: [1, 2, 3, 4, 5],
        EDUCATION_LEVEL_ID: 1,
      },
      {
        name: "2. Суурь боловсрол",
        EDUCATION_LEVEL: "LOWER_SECONDARY",
        levels: [6, 7, 8, 9],
        EDUCATION_LEVEL_ID: 2,
      },
      {
        name: "3. Бүрэн дунд боловсрол",
        EDUCATION_LEVEL: "UPPER_SECONDARY",
        levels: [10, 11, 12],
        EDUCATION_LEVEL_ID: 3,
      },
    ],
    headers: [
      { text: "No.", value: "index", width: "10px" },
      { text: "Нэр", value: "DISPLAY_NAME" },
    ],
    teachers: null,
    classPrograms: null,
    lessonCategories: null,
    lessonTypes: [
      { value: 1, name: "Заавал" },
      { value: 2, name: "Сонгон" },
      { value: 4, name: "Бага анги" },
    ],
    mustorselection: fb.EYESH_APP ? 3 : 1,
    addNewLessonToTeacher: false,
    selectedLessonCategory: null,
    editedItem: {
      description: null,
    },
    selectedYear: null,
    lessons: null,
    chooseStudyPrograms: null,
    showStudyProgramDialog: false,
    selectedStudyProgram: null,
    selectedClassGroup: null,
    selectedLessons: null,
    deletingClasses: [],
    lessonsHeaders: [{ text: "Name", value: "name2", width: "100%" }],
    toggleTableSchedule: true,
    week: [
      {
        name: "Даваа",
        value: "1",
      },
      {
        name: "Мягмар",
        value: "2",
      },
      {
        name: "Лхавга",
        value: "3",
      },
      {
        name: "Пүрэв",
        value: "4",
      },
      {
        name: "Баасан",
        value: "5",
      },
    ],
    eeljs: ["1", "2", "3", "4", "5", "6", "7"],
    selectedDayEelj: "1",
    selectedCalendarLesson: null,
    newSchedule: false,
    addNewCalendarDialog: false,
    selectedTeacher: null,
    selectedCalendarData: null,
    renderComponent: true,
    selectedEelj: null,
    selectedDay: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filtered_STUDY_PROGRAM() {
      if (this.XselectedStudyProgram)
        return this.allPrograms.find(
          (studyprogram) =>
            studyprogram.PROGRAM_OF_STUDY_ID ==
            this.selectedProgram.PROGRAM_OF_STUDY_ID
        );
      else return this.allPrograms;
    },
    filteredTeachers() {
      var list2 = [];
      if (this.teachers) {
        // var list = this.teachers.map((teacher, index) => {
        //   teacher.index = index + 1;
        //   return teacher;
        // });
        list2 = this.teachers;
        if (this.selectedZAN) {
          list2 = this.list2
            .filter(
              (tt) =>
                tt.academicOrganizationId ==
                this.selectedZAN.academicOrganizationId
            )
            .map((teacher, index) => {
              teacher.index = index + 1;
              return teacher;
            });
        }
        if (this.selectedLevel) {
          console.log(this.selectedLevel);
          if (this.selectedLevel["EDUCATION_LEVEL_ID"] == 1) {
            list2 = list2
              .filter(
                (tt) => tt.INSTRUCTOR_TYPE_ID && tt.INSTRUCTOR_TYPE_ID < 3
              )
              .map((teacher, index) => {
                teacher.index = index + 1;
                return teacher;
              });
          } else {
            list2 = list2
              .filter(
                (tt) => tt.INSTRUCTOR_TYPE_ID && tt.INSTRUCTOR_TYPE_ID > 3
              )
              .map((teacher, index) => {
                teacher.index = index + 1;
                return teacher;
              });
          }
        } else {
          list2 = [];
        }
        return list2;
      } else return [];
    },

    filteredDepartments() {
      // if(this.teachers) return this.teachers.filter(item =>{
      //   return item.firstName
      // })
      if (this.departments)
        // return this.departments.filter((item) =>
        //   this._getPrograms().includes(item.index)
        // );
        return this.departments;
      else return [];
    },
    filterLessonCategories() {
      var list = [];
      var counter = 0;
      if (this.lessonCategories) {
        for (var ll of this.lessonCategories) {
          if (ll.lessonType == this.mustorselection) {
            counter++;
            ll.name3 = counter + ". " + ll.name2;
            list.push(ll);
          }
        }
      }
      return list;
    },
  },
  watch: {
    selectedPlan(val) {
      this.selectedPlan = val;
      if (this.selectedPlan) {
        this.loading = true;
        this.selectedPlan.courses = null;
        val.ref
          .collection("courses")
          .orderBy("COURSE_NAME", "asc")
          .get()
          .then((docs) => {
            this.selectedPlan.courses = [];
            var counter = 0;
            docs.docs.forEach((doc) => {
              counter++;
              let course = doc.data();
              course.id = doc.id;
              course.ref = doc.ref;
              // course.name2 =
              //   counter +
              //   ". " +
              //   course.COURSE_NAME +
              //   " (" +
              //   course.PROGRAM_PLAN_ID +
              //   ")";
              course.name2 = counter + ". " + course.COURSE_NAME;
              this.selectedPlan.courses.push(course);
            });
            this.loading = false;
            this.$forceUpdate();
          });
      }
    },
    addNewCalendarDialog(val) {
      if (val == false) {
        this.selectedCalendarLesson = null;
      }
    },
    addNewLessonToTeacher(val) {
      if (val == false) this._closeNewLessonDialog();
    },
    // XselectedStudyProgram() {
    //   if (this.selectedDepartment) this._getSelectedCourses2();
    // },
    selectedLevel(val) {
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        // .where("startYear", "==", 2022)
        .where("index", "in", val.levels)
        .orderBy("index", "asc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  dep.classGroups.push(prog);
                });
              });
            this.departments.push(dep);
          });
        });
      //TODOZ
      this.userData.school.ref
        .collection("schoolPrograms")
        .where("EDUCATION_LEVEL", "==", val.EDUCATION_LEVEL)
        .get()
        .then((docs) => {
          this.allPrograms = [];
          this.XselectedStudyProgram = null;
          docs.docs.forEach((doc) => {
            let sp = doc.data();
            sp.id = doc;
            sp.ref = doc.ref;
            // sp.stages = null;
            // sp.ref
            //   .collection("stages")
            //   .get()
            //   .then((docs) => {
            //     sp.stages = [];
            //     docs.docs.forEach((ss) => {
            //       let stage = ss.data();
            //       stage.ref = ss.ref;
            //       stage.id = ss.id;
            //       stage.plans = null;
            //       stage.ref
            //         .collection("plans")
            //         .get()
            //         .then((plans) => {
            //           stage.plans = [];
            //           plans.forEach((pp) => {
            //             pp.courses = null;
            //             pp.ref
            //               .collection("courses")
            //               .get()
            //               .then((docs) => {
            //                 pp.courses = [];
            //                 docs.docs.forEach((doc) => {
            //                   pp.courses.push(doc.data());
            //                 });
            //               });
            //             stage.plans.push(pp);
            //           });
            //         });

            //       sp.stages.push(stage);
            //     });
            //   });
            // this.allPrograms.push({
            //   PROGRAM_OF_STUDY: sp,
            //   PROGRAM_OF_STUDY_NAME: sp.PROGRAM_OF_STUDY_NAME,
            //   ref: sp.ref,
            //   id: sp.id
            // });

            this.allPrograms.push(sp);
            // if (!this.XselectedStudyProgram) this.XselectedStudyProgram = sp;
          });
        });
    },
  },
  created() {
    this.selectedLevel = {
      name: "1. Бага боловсрол",
      EDUCATION_LEVEL: "PRIMARY",
      levels: [1, 2, 3, 4, 5],
      EDUCATION_LEVEL_ID: 1,
    };
    this.selectedYear = this.userData.school
      ? this.userData.school.currentYear
      : null;
    this.userData.school.ref.collection("lessons-"+this.userData.school.currentYear).onSnapshot((docs) => {
      this.lessons = [];
      docs.forEach(async (doc) => {
        let lesson = doc.data();
        lesson.id = doc.id;
        lesson.ref = doc.ref;
        this.lessons.push(lesson);
        //YTODO
      });
    });
    this.userData.school.ref
      .collection("zanList")
      .orderBy("academicOrganizationId", "asc")
      .onSnapshot((querySnapshot) => {
        this.zanList = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          counter++;
          item.name2 =
            counter +
            ". " +
            item.academicOrgName +
            "(" +
            item.academicOrganizationId +
            ")";
          this.zanList.push(item);
        });
      });
    // fb.db
    //   .collection("categories")
    //   .orderBy("name", "asc")
    //   .onSnapshot((querySnapshot) => {
    //     this.lessonCategories = [];
    //     querySnapshot.forEach((doc) => {
    //       let lesson = doc.data();
    //       lesson.id = doc.id;
    //       lesson.ref = doc.ref;
    //       lesson.name2 = lesson.name + " - " + lesson.lessonTypeName;
    //       lesson.my = false;
    //       this.lessonCategories.push(lesson);
    //     });
    // if (this.userData.school) {
    //   this.userData.school.ref
    //     .collection("categories")
    //     .orderBy("name", "asc")
    //     .onSnapshot((querySnapshot) => {
    //       //this.lessonCategories = [];
    //       querySnapshot.forEach((doc) => {
    //         let lesson = doc.data();
    //         lesson.id = doc.id;
    //         lesson.ref = doc.ref;
    //         lesson.name2 =
    //           lesson.name + " - " + lesson.lessonTypeName + " /сургуулийн/";
    //         lesson.my = true;
    //         this.lessonCategories.push(lesson);
    //       });
    //     });
    // }
    // });

    if (this.userData.school != null) {
      // this.loading = true;
      this.userData.school.ref
        .collection("teachers")
        .where("role", "==", "teacher")
        .where("deleted", "==", false)
        .orderBy("firstName", "asc")
        .onSnapshot((docs) => {
          this.teachers = [];
          docs.forEach(async (doc) => {
            let teacher = doc.data();
            teacher.id = doc.id;
            teacher.ref = doc.ref;
            await teacher.ref
              .collection("calendarData-" + this.userData.school.currentYear)
              .onSnapshot((docs) => {
                teacher.calendarData = [];
                docs.forEach((doc) => {
                  let calendarData = doc.data();
                  calendarData.ref = doc.ref;
                  calendarData.id = doc.id;
                  teacher.calendarData.push(calendarData);
                });
              });
            this.teachers.push(teacher);
          });
          this.loading = false;
        });
    }
  },
  methods: {
    _print(teacher) {
      console.log(teacher.ref.path);
    },
    _getShortName(bulkText) {
      if (bulkText) {
        bulkText = bulkText.replaceAll('"', "");
        var arrayOfLines = bulkText.match(/[^\r\n\s]+/g);
        var shortName = "";
        var counter = 0;
        arrayOfLines.forEach((txt) => {
          counter++;
          if (txt && txt.length > 0 && counter < 3)
            shortName = shortName + txt[0];
        });
        return shortName.toUpperCase();
      } else {
        return null;
      }
    },
    _saveTeacherDetail(teacher) {
      teacher.ref.update(teacher).then(() => {
        console.log("saved!!");
      });
    },

    _loadTeacherInfo(teacher) {
      console.log("teacher....");
      this.teacherZANloading = true;
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then(async (res) => {
          this.token = res.data.token;
          if (this.userData.token == "superadmin") console.log(this.token);
          this.teacherZANloading = true;
          console.log("loading zan teacher");
          var zanInfo = await esisAPIS.getESIS_ZAN_TEACHER(
            this.token,
            teacher.id
          );

          if (zanInfo && zanInfo.length > 0) {
            teacher.ref.update({ zan: zanInfo[0] }).then(() => {
              this.teacherZANloading = false;
            });
          }
          console.log("loading teacher loading");
          this.teacherZANloading = true;
          var teacherLessons = await esisAPIS.getESIS_ZAN_TEACHER_LESSONS(
            this.token,
            teacher.id
          );
          if (teacherLessons && teacherLessons.length > 0) {
            teacher["lessonsESIS-" + this.userData.school.currentYear] =
              teacherLessons;
            teacher.lessonsESIS_empty = false;
            teacher.ref.update(teacher).then(() => {
              this.teacherZANloading = false;
            });
          } else {
            teacher.ref.update({ lessonsESIS_empty: true }).then(() => {
              this.teacherZANloading = false;
            });
          }
        });
    },
    _editTeacherInfo(teacher) {
      console.log(teacher);
    },
    _getZAN_NAME(teacher) {
      console.log(teacher, this.zanList);
      if (this.zanList && teacher && teacher.academicOrganizationId) {
        var x = this.zanList.find(
          (el) => teacher.academicOrganizationId == el.academicOrganizationId
        );
        if (x) return "x.academicOrgName";
      } else if (teacher) return teacher.academicOrganizationId;
      else return "null ";
    },
    deleteSchedule() {
      var yes = confirm("Та устгаxыг xүсэж байна уу?");
      if (yes) {
        this.selectedCalendarData.ref.delete();
        this.selectedCalendarLesson.ref
          .collection("calendarData")
          .where("dayOfWeek", "==", this.selectedDay.value)
          .where("xeelj", "==", this.selectedEelj)
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              doc.ref.delete();
            });
          });
        setTimeout(() => {
          this.forceRerender();
          this.addNewCalendarDialog = false;
          this.selectedCalendarLesson = null;
        }, 100);
      }
    },
    _saveSchedule() {
      if (this.selectedCalendarLesson) {
        this.selectedCalendarLesson.ref.collection("calendarData").add({
          dayName: this.selectedDay.name,
          dayOfWeek: this.selectedDay.value,
          dayEelj: this.selectedDayEelj,
          xeelj: this.selectedEelj,
          createdAt: new Date(),
        });

        this.selectedTeacher.ref
          .collection("calendarData-" + this.userData.school.currentYear)
          .add({
            selectedLesson: this.selectedCalendarLesson,
            dayName: this.selectedDay.name,
            dayOfWeek: this.selectedDay.value,
            dayEelj: this.selectedDayEelj,
            xeelj: this.selectedEelj,
            createdAt: new Date(),
          });

        setTimeout(() => {
          this.forceRerender();
          this.addNewCalendarDialog = false;
        }, 100);
      }
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    _closeNewLessonDialog() {
      this.addNewLessonToTeacher = false;
      this.selectedCourse = null;
      this.editedItem = {};
      // this.selectedLessonCategory = null;
      this.selectedDepartment = null;
      this.selectedProgram = null;
      this.selectedPlan = null;
      this.loading = false;
    },

    deletingLessons() {
      var yes = confirm("Та энэ xичээлийг устгаxдаа итгэлтэй байна уу?");
      if (yes) {
        var deletingLessons = this.selectedLessons.filter(
          (lesson) => lesson.deleting == true
        );
        for (var lesson of deletingLessons) {
          var tmp = {};
          tmp["totalLessons-" + this.userData.school.currentYear] =
            fb.firestore.FieldValue.increment(-1);
          this.selectedTeacher.ref.update(tmp);

          // byTeachers: fb.firestore.FieldValue.arrayRemove({
          //     teacherRef: this.selectedTeacher.ref,
          //     teacherFirstName: this.selectedTeacher.firstName,
          //     teacherLastName: this.selectedTeacher.lastName,
          //     teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
          //       ? this.selectedTeacher.DISPLAY_NAME
          //       : null,
          //     teacherId: this.selectedTeacher.id,
          //   }),

          // var selectedTeacherInfo = {
          //   teacherRef: this.selectedTeacher.ref,
          //   teacherFirstName: this.selectedTeacher.firstName,
          //   teacherLastName: this.selectedTeacher.lastName,
          //   teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
          //     ? this.selectedTeacher.DISPLAY_NAME
          //     : null,
          //   teacherId: this.selectedTeacher.id,
          // };
          var indexToDelete = lesson.byTeachers.findIndex(
            (t) => t.teacherId == this.selectedTeacher.id
          );
          if (indexToDelete !== -1) {
            lesson.byTeachers.splice(indexToDelete, 1);
            lesson.ref
              .update({
                byTeachers: lesson.byTeachers,
                teacherRefs: fb.firestore.FieldValue.arrayRemove(
                  this.selectedTeacher.ref
                ),
              })
              .then(() => {
                //TODOZ
                lesson.ref
                  .collection("executions")
                  .where(
                    "selectedLesson.teacherRefs",
                    "array-contains",
                    this.selectedTeacher.ref
                  )
                  .get()
                  .then((docs) => {
                    docs.forEach((doc) => {
                      doc.ref.update({
                        byTeachers: fb.firestore.FieldValue.arrayRemove({
                          teacherRef: this.selectedTeacher.ref,
                          teacherFirstName: this.selectedTeacher.firstName,
                          teacherLastName: this.selectedTeacher.lastName,
                          teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
                            ? this.selectedTeacher.DISPLAY_NAME
                            : null,
                          teacherId: this.selectedTeacher.id,
                        }),
                        teacherRefs: fb.firestore.FieldValue.arrayRemove(
                          this.selectedTeacher.ref
                        ),
                      });
                    });
                  });
              });
          }
        }
        this._closeNewLessonDialog();
      }
    },
    _closeStudyProgramDialog() {
      this.chooseStudyPrograms = null;
      this.selectedClassGroup = null;
      this.selectedStudyProgram = null;
      this.showStudyProgramDialog = false;
    },
    _saveStudyProgramDialog() {
      console.log(this.selectedStudyProgram, this.selectedClassGroup);
      if (this.selectedClassGroup && this.selectedClassGroup.PROGRAM_OF_STUDY) {
        this.selectedClassGroup.PROGRAM_OF_STUDY.ref = null;
        this.selectedClassGroup.PROGRAM_OF_STUDY.id = null;
        this.selectedClassGroup.ref
          .update({
            PROGRAM_OF_STUDY: this.selectedClassGroup.PROGRAM_OF_STUDY,
          })
          .then(() => {
            this._closeStudyProgramDialog();
          });
      }
    },
    _selectStudyProgram(program) {
      this.selectedClassGroup = program;
      this.userData.school.ref
        .collection("schoolPrograms")
        .where("EDUCATION_LEVEL", "==", this.selectedLevel.EDUCATION_LEVEL)
        .get()
        .then((docs) => {
          var counter = 0;
          this.chooseStudyPrograms = docs.docs.map((doc) => {
            counter++;
            let studyProgram = doc.data();
            studyProgram.id = doc.id;
            studyProgram.ref = doc.ref;
            studyProgram.index = counter;
            return studyProgram;
          });
          this.showStudyProgramDialog = true;
        });
    },
    _selectDepartment() {
      this.userData.school.ref
        .collection("schoolPrograms")
        .where("EDUCATION_LEVEL", "==", this.selectedLevel.EDUCATION_LEVEL)
        .where("PROGRAM_CLASSIFICATION", "==", "2")
        .get()
        .then((docs) => {
          if (docs.size == 1) {
            this.filteredDepartments.forEach((dep) => {
              dep.classGroups.forEach((program) => {
                if (!program.PROGRAM_OF_STUDY) {
                  program.ref.update({
                    PROGRAM_OF_STUDY: docs.docs[0].data(),
                  });
                }
              });
            });
          }
        });
    },
    _getCellData(program, teacher) {
      if (this.lessons) {
        var found = this.lessons.filter(
          (lesson) =>
            lesson.byTeachers.find((item) => item.teacherId == teacher.id) &&
            lesson.programId == program.id
        );
        if (found && found.length > 0) {
          return found.length;
        }
      } else {
        return null;
      }
    },
    _getCellScheduleData(day, eelj, teacher) {
      var found = teacher.calendarData.find(
        (calendar) =>
          calendar.dayOfWeek == day.value &&
          calendar.xeelj == eelj &&
          calendar.dayEelj == this.selectedDayEelj
      );
      if (found) {
        return found.selectedLesson.classGroups[0].programData
          .STUDENT_GROUP_NAME;
      } else {
        return null;
      }
    },
    _save() {
      //eeljit hicheel save
      if (this.selectedCourse) {
        var lesson = {
          byTeachers: fb.firestore.FieldValue.arrayUnion({
            teacherRef: this.selectedTeacher.ref,
            teacherFirstName: this.selectedTeacher.firstName,
            teacherLastName: this.selectedTeacher.lastName,
            teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
              ? this.selectedTeacher.DISPLAY_NAME
              : null,
            teacherId: this.selectedTeacher.id,
          }),
          teacherRefs: fb.firestore.FieldValue.arrayUnion(
            this.selectedTeacher.ref
          ),

          year: this.userData.school.currentYear,
          programId: this.selectedProgram.id,
          programRef: this.selectedProgram.ref,
          departmentId: this.selectedDepartment.id,

          // categoryId: this.selectedLessonCategory.id,
          // COURSE_CONTACT_HOURS: 99,
          // categoryName: this.selectedLessonCategory.name,
          // categoryRef: this.selectedLessonCategory.ref,

          courseInfo: this.selectedCourse,
          studyPlanInfo: {
            PROGRAM_PLAN_ID: this.selectedPlan["PROGRAM_PLAN_ID"],
            PROGRAM_PLAN_NAME: this.selectedPlan["PROGRAM_PLAN_NAME"],
          },

          classGroupRefs: [this.selectedProgram.ref],
          classGroups: [
            {
              classGroupFullName:
                this.selectedDepartment.name +
                this.selectedProgram.name.toLowerCase(),
              classGroupName: this.selectedProgram.name.toUpperCase(),
              classGroupRef: this.selectedProgram.ref,
              departmentName: this.selectedDepartment.name,
              departmentRef: this.selectedDepartment.ref,
              programData: this.selectedProgram,
              ACADEMIC_LEVEL: this.selectedDepartment.ACADEMIC_LEVEL
                ? this.selectedDepartment.ACADEMIC_LEVEL.toString()
                : this.selectedDepartment.index,
            },
          ],
          createdAt: new Date(),
          deleted: false,
          description: null,
          isGroupBased: false,
          lessonType:
            this.selectedCourse.ENROLLMENT_CATEGORY == "MANDATORY"
              ? 1
              : this.selectedCourse.ENROLLMENT_CATEGORY == "ELECTIVE"
              ? 2
              : 0,
          lessonTypeName: this.selectedCourse.ENROLLMENT_CATEGORY_NAME,
        };

        var tmp = {};
        tmp["totalLessons-" + this.userData.school.currentYear] =
          fb.firestore.FieldValue.increment(1);
        this.selectedTeacher.ref.update(tmp);

        tmp["DISPLAY_NAME"] = this.selectedTeacher["DISPLAY_NAME"];
        tmp["teacherRef"] = this.selectedTeacher.ref;
        this.userData.school.ref
          .collection("_statistics_teachers")
          .doc(this.selectedTeacher.id)
          .set(tmp, { merge: true })
          .then(() => {
            console.log(this.selectedTeacher.ref.path);
          });

        this.userData.school.ref
          .collection("lessons-" + this.userData.school.currentYear)
          .doc(this.selectedProgram.id + "-" + this.selectedCourse["COURSE_ID"])
          .get()
          .then(async (doc) => {
            doc.ref.set(lesson, { merge: true }).then(() => {
              this._closeNewLessonDialog();
            });

            // var executions = await doc.ref.collection("executions").get();
            // if (executions.size == 0) {
            //   for (
            //     var i = 1;
            //     i <= lesson.courseInfo.COURSE_CONTACT_HOURS;
            //     i++
            //   ) {
            //     // var teachedAt = new Date(new Date().getTime() + 86400000 * i); //day emulator, 86400000==1day
            //     var selectedClassGroup = lesson.classGroups[0];
            //     selectedClassGroup.name2 =
            //       selectedClassGroup.classGroupFullName.toUpperCase();
            //     var attendance = {
            //       byTeachers: fb.firestore.FieldValue.arrayUnion({
            //         teacherRef: this.selectedTeacher.ref,
            //         teacherFirstName: this.selectedTeacher.firstName,
            //         teacherLastName: this.selectedTeacher.lastName,
            //         teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
            //           ? this.selectedTeacher.DISPLAY_NAME
            //           : null,
            //         teacherId: this.selectedTeacher.id,
            //       }),
            //       teacherRefs: fb.firestore.FieldValue.arrayUnion(
            //         this.selectedTeacher.ref
            //       ),
            //       closed: false,
            //       createdAt: new Date(),
            //       day: null,
            //       deleted: false,
            //       month: null,
            //       selectedClassGroup: selectedClassGroup,
            //       selectedLesson: lesson,
            //       studentsAbsent: 0,
            //       studentDisturbing: 0,
            //       studentsExcused: 0,
            //       studentsNoNotebook: 0,
            //       studentsNoPen: 0,
            //       studentsOnline: 0,
            //       studentsPresent:
            //         selectedClassGroup.programData.numberOfStudents,
            //       studentsSick: 0,
            //       teachedAt: null,
            //       xeelj: null,
            //       year: null,
            //       idd: i,
            //     };
            //     doc.ref
            //       .collection("executions")
            //       .doc(i.toString())
            //       // .doc(
            //       // attendance.year +
            //       //   "-" +
            //       //   attendance.month +
            //       //   "-" +
            //       //   attendance.day +
            //       //   "-" +
            //       //   xeelj +
            //       //   "-" +
            //       //   (this.userData.id +
            //       //     "-" +
            //       //     selectedClassGroup.classGroupRef.id +
            //       //     "-" +
            //       //     lesson.categoryRef.id) +
            //       //   "-" +
            //       //   selectedClassGroup.classGroupRef.id
            //       // )
            //       .set(attendance);
            //   }
            //   //YTODO loading gargah as teacher com is slower
            // } else {
            //   executions.forEach((execution) => {
            //     execution.ref.update({
            //       byTeachers: fb.firestore.FieldValue.arrayUnion({
            //         teacherRef: this.selectedTeacher.ref,
            //         teacherFirstName: this.selectedTeacher.firstName,
            //         teacherLastName: this.selectedTeacher.lastName,
            //         teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
            //           ? this.selectedTeacher.DISPLAY_NAME
            //           : null,
            //         teacherId: this.selectedTeacher.id,
            //       }),
            //       teacherRefs: fb.firestore.FieldValue.arrayUnion(
            //         this.selectedTeacher.ref
            //       ),
            //     });
            //   });
            // }
          });
      }
      // console.log(
      //   this.selectedLessonCategory.id,
      //   this.selectedLessonCategory.name
      // );
    },

    async _getSelectedCourses2() {
      console.log(this.XselectedStudyProgram);
      if (this.XselectedStudyProgram) {
        this.XselectedStudyProgram.lessons491 = null;
        this.XselectedStudyProgram.ref
          .collection("stages")
          .where(
            "ACADEMIC_LEVEL",
            "==",
            this.selectedDepartment.index.toString()
          )
          .onSnapshot((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              await doc.ref.collection("plans").onSnapshot((plan) => {
                plan.docs.forEach(async (doc) => {
                  await doc.ref.collection("courses").onSnapshot((docs) => {
                    this.XselectedStudyProgram.lessons491 = [];
                    var counter = 0;
                    docs.docs.forEach((course) => {
                      counter++;
                      let c = course.data();
                      c.id = course.id;
                      c.ref = course.ref;
                      c.COURSE_NAME2 =
                        counter +
                        ". " +
                        c.COURSE_NAME +
                        " (" +
                        c.COURSE_ID +
                        ")";
                      this.XselectedStudyProgram.lessons491.push(c);
                    });
                    this.$forceUpdate();
                  });
                });
              });
            });
          });
      }
    },
    _getSelectedCourses() {
      var list = [];
      if (
        this.XselectedStudyProgram &&
        this.selectedDepartment &&
        this.XselectedStudyProgram.stages
      ) {
        for (const stage of this.XselectedStudyProgram.stages) {
          if (
            stage.ACADEMIC_LEVEL == this.selectedDepartment.index.toString() &&
            stage.plans
          ) {
            for (const plan of stage.plans) {
              if (plan.courses) {
                for (const lesson of plan.courses) {
                  // console.log(lesson.COURSE_NAME);
                  list.push(lesson);
                }
              }
            }
          }
        }
      }
      return list;
    },
    _clickScheduleCell(day, eelj, teacher) {
      this.selectedDay = day;
      this.selectedEelj = eelj;
      this.selectedTeacher = teacher;

      this.selectedTeacher.lessons = [];
      var counter = 0;
      for (const lesson of this.lessons) {
        if (
          lesson.teacherRefs.find(
            (ref) => ref.path == this.selectedTeacher.ref.path
          )
        ) {
          counter++;
          lesson.name2 =
            counter +
            ". " +
            lesson.courseInfo.COURSE_NAME +
            " ( " +
            (lesson.classGroups[0].programData.STUDENT_GROUP_NAME
              ? lesson.classGroups[0].programData.STUDENT_GROUP_NAME
              : lesson.classGroups[0].programData.fullName
            ).toUpperCase() +
            " )";

          this.selectedTeacher.lessons.push(lesson);
        }
      }

      var found = teacher.calendarData.find(
        (calendar) =>
          calendar.dayOfWeek == day.value &&
          calendar.xeelj == eelj &&
          calendar.dayEelj == this.selectedDayEelj
      );
      if (found) {
        this.selectedCalendarData = found;
        this.selectedCalendarLesson = found.selectedLesson;
        this.newSchedule = true;
      } else {
        this.newSchedule = false;
      }

      this.addNewCalendarDialog = true;
    },
    async _clickCell(dep, program, teacher) {
      if (dep && program && teacher) {
        this.selectedDepartment = dep;
        this.selectedProgram = program;
        this.selectedTeacher = teacher;

        // await this._getSelectedCourses2();

        this.selectedProgram.plans = null;
        this.loading = true;
        var xx = await this.userData.school.ref
          .collection("schoolPrograms")
          .doc(this.selectedProgram.PROGRAM_OF_STUDY_ID.toString())
          .collection("stages")
          .doc(this.selectedProgram.PROGRAM_STAGE_ID.toString())
          .collection("plans")
          .get();
        this.selectedProgram.plans = [];
        var counter = 0;
        xx.docs.forEach((doc) => {
          counter++;
          let plan = doc.data();
          plan.id = doc.id;
          plan.ref = doc.ref;
          plan.name2 = counter + ". " + plan.PROGRAM_PLAN_NAME;
          this.selectedProgram.plans.push(plan);
        });

        this.$forceUpdate();

        this.$nextTick(() => {
          this.loading = false;
          if (
            this.selectedProgram.plans &&
            this.selectedProgram.plans.length == 1
          )
            this.selectedPlan = this.selectedProgram.plans[0];
          this.addNewLessonToTeacher = true;
        });

        this.selectedLessons = this.lessons.filter(
          (lesson) =>
            lesson.byTeachers.find((item) => item.teacherId == teacher.id) &&
            lesson.programId == program.id
        );
        if (this.selectedLessons && this.selectedLessons.length > 0) {
          for (var lesson of this.selectedLessons) {
            lesson.name2 = lesson.name + " " + lesson.lessonTypeName;
          }
        }
        // this.addNewItem = true;
      }

      // if (this.selectedLevel.EDUCATION_LEVEL) {

      //   this.userData.school.ref
      //     .collection("schoolPrograms")
      //     .where("EDUCATION_LEVEL", "==", this.selectedLevel.EDUCATION_LEVEL)
      //     .get()
      //     .then((docs) => {
      //       var counter = 0;
      //       this.chooseStudyPrograms = docs.docs.map((doc) => {
      //         counter++;
      //         let studyProgram = doc.data();
      //         studyProgram.id = doc.id;
      //         studyProgram.ref = doc.ref;
      //         studyProgram.index = counter;
      //         if (!this.XselectedStudyProgram) {
      //           this.XselectedStudyProgram = studyProgram;
      //           this.XselectedStudyProgram.lessons491 = null;
      //           this.XselectedStudyProgram.ref
      //             .collection("stages")
      //             .where("ACADEMIC_LEVEL", "==", program.departmentName)
      //             .onSnapshot((querySnapshot) => {
      //               // this.lessonCategories = [];
      //               // this.lessons491 = [];
      //               querySnapshot.forEach((doc) => {
      //                 doc.ref.collection("plans").onSnapshot((plan) => {
      //                   plan.docs.forEach((doc) => {
      //                     doc.ref.collection("courses").onSnapshot((docs) => {
      //                       this.XselectedStudyProgram.lessons491 = [];
      //                       var counter = 0;
      //                       docs.docs.forEach((course) => {
      //                         counter++;
      //                         let c = course.data();
      //                         c.id = course.id;
      //                         c.ref = course.ref;
      //                         c.COURSE_NAME = counter + ". " + c.COURSE_NAME;
      //                         this.XselectedStudyProgram.lessons491.push(c);
      //                       });
      //                     });
      //                   });
      //                 });

      //                 // let lesson = doc.data();
      //                 // lesson.id = doc.id;
      //                 // lesson.ref = doc.ref;
      //                 // lesson.name2 =
      //                 //   lesson.name + " - " + lesson.lessonTypeName;
      //                 // lesson.my = false;
      //                 // this.lessons491.push(lesson);
      //               });
      //             });
      //         }
      //         return studyProgram;
      //       });
      //     });
      // }

      // if (dep && program && teacher) {
      //   console.log(dep, program, teacher);
      //   this.userData.school.ref
      //     .collection("lessons")
      //     .doc(teacher.id + "-" + program.id)
      //     .set({
      //       name: "xxx",
      //       teacherRef: teacher.ref,
      //       teacherFirstName: teacher.firstName,
      //       teacherLastName: teacher.lastName,
      //       teacherDisplayName: teacher.DISPLAY_NAME,
      //       year: 2023,
      //     });
      //   // console.log(
      //   //   dep.name,
      //   //   dep.ref.path,
      //   //   program.name,
      //   //   program.ref.path,
      //   //   teacher.ref.path,
      //   //   teacher.firstName
      //   // );
      //}
    },
    _addNewItem() {
      this.addNewLessonToTeacher = true;
      if (this.selectedLessonCategory) {
        this.editedItem.name = this.selectedLessonCategory.name;
      }
    },

    openMenu() {
      this.menuOpen = true;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    _getPrograms() {
      var classes = [];
      if (this.classLevel1) {
        classes.push(1);
        classes.push(2);
        classes.push(3);
        classes.push(4);
        classes.push(5);
      }
      if (this.classLevel2) {
        classes.push(6);
        classes.push(7);
        classes.push(8);
        classes.push(9);
      }
      if (this.classLevel3) {
        classes.push(10);
        classes.push(11);
        classes.push(12);
      }
      return classes;
    },

    _getWidth(dep) {
      if (dep && dep.classGroups && dep.classGroups.length > 0) {
        console.log(parseInt((100 / dep.classGroups.length).toFixed(0)));
        return 14;
      }
      return parseInt((100 / dep.classGroups.length).toFixed(0));
    },
  },
  filters: {
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title2] {
  position: relative;
}

[data-title-teacher]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title-teacher]:after {
  content: attr(data-title-teacher);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: rgb(255, 163, 163);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title-teacher] {
  position: relative;
}

.hoveredCell:hover {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
.huvaariTable td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.huvaariTable th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
  color: #2196f3;
  font-weight: normal;
}
.custom-tooltip {
  color: red !important;
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  position: absolute;
  bottom: -1.6em;
  left: 100%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
.xCell:hover {
  background: yellowgreen;
  color: white;
  cursor: pointer;
}
.selectedCell {
  background: yellowgreen;
  color: white;
}
.programSelected1 {
  background: blue !important;
  color: white !important;
}
.programSelected2 {
  background: yellow !important;
}
</style>
